import {Controller} from "@hotwired/stimulus"


export default class extends Controller {
    static targets = ['draggable', 'ordering', 'dragHandle', 'storage']
    remove_over = false;
    handlers = [];

    connect() {
        const self = this;
        // @ts-ignore
        this.dragHandleTargets.forEach(t => t.addEventListener('mousedown', self.mouse_down))
        // @ts-ignore
        this.draggableTargets.forEach(t => t.addEventListener('dragstart', function(e){
            self.drag_start(e, self);
        }))
        // @ts-ignore
        this.draggableTargets.forEach(t => t.addEventListener('dragend', function(e){
            self.drag_stop(e, self);
        }))
        // @ts-ignore
        this.orderingTargets.forEach(t => t.addEventListener('dragenter', function(e){
            self.node_drag_over(e, self);
        }))
        // @ts-ignore
        this.orderingTargets.forEach(t => t.addEventListener('dragover', function(e){
            self.node_drag_over(e, self);
        }))
        // @ts-ignore
        this.orderingTargets.forEach(t => t.addEventListener('drop', self.drag_drop))
    }

    mouse_down(e){
        const dragged_elem = e.currentTarget.parentElement;
        dragged_elem.draggable = true;
    }

    drag_start(e, self){
        e.dataTransfer.setData('application/node', e.currentTarget.id);
        self.storageTarget.dataset.node = e.currentTarget.id;
        self.draggableTargets.forEach((target) => {
            target.classList.remove('hover:bg-gray-200')
        });
        e.currentTarget.classList.add('bg-gray-200');
        const parent_id = e.currentTarget.parentElement.id;
        e.dataTransfer.setData('application/priority', parent_id.split('_')[1]);
    }

    drag_stop(e, self){
        e.currentTarget.draggable = false;
        self.storageTarget.dataset.node = null;
        self.draggableTargets.forEach((target) => {
            target.classList.add('hover:bg-gray-200')
        });
        e.currentTarget.classList.remove('bg-gray-200');
    }

    drag_drop(e){
        // @ts-ignore
        const new_priority = parseInt(this.id.split('_')[1]);
        const old_priority = parseInt(e.dataTransfer.getData('application/priority'));

        if(old_priority == new_priority){
            return;
        }

        const elem = this;
        // @ts-ignore
        const elems = elem.parentNode.children;
        const data = {
            nodes: []
        }
        Array.from(elems).forEach(node => {
            // @ts-ignore
            const node_pos = node.id.split('_')[1]
            // @ts-ignore
            const node_id = node.children[0].id;
            data['nodes'].push({
                node_pos: node_pos,
                node_id: node_id
            })
            const node_name = node_id.split("_")[0];
            const new_id = `${node_name}_${node_pos}`;
            // @ts-ignore
            node.children[0].id = new_id;
            // @ts-ignore
            node.getElementsByTagName('a')[0].href = node.getElementsByTagName('a')[0].href.replace(node_id, new_id)
            // @ts-ignore
            node.getElementsByTagName('button')[0].formAction =  node.getElementsByTagName('button')[0].formAction.replace(node_id, new_id)
        })

        //@ts-ignore
        const token = document.getElementsByName('csrf-token')[0].content;
        fetch(`/atis_formats/${document.getElementById('format_id').dataset.id}/reorder_nodes`, {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': token,
                'Content-Type': 'application/json',
                'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
            },
            body: JSON.stringify(data),
            credentials: 'same-origin'
        }).then(r => {})
    }

    node_drag_over(e, self){
        e.preventDefault();

        const id = self.storageTarget.dataset.node;
        if(id === null || id === undefined){
            return;
        }

        const origin_child = document.getElementById(id)
        const target_parent = e.currentTarget;
        if(target_parent.children[0] == origin_child){
            return;
        }
        const origin_parent = document.getElementById(id).parentElement;
        const target_child = target_parent.children[0];

        origin_parent.appendChild(target_child);
        target_parent.appendChild(origin_child);
    }
}