import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails";

export default class extends Controller{
    static targets = ['input', 'value', 'selectList']

    connect(){
        const self = this;
        window.addEventListener('click', function(e){
            // @ts-ignore
            if(self.selectListTargets.length > 0){
                // @ts-ignore
                if(!(self.inputTarget == e.target || self.inputTarget.contains(e.target))){
                    // @ts-ignore
                    self.selectListTarget.classList.add('hidden');
                } else {
                    // @ts-ignore
                    self.selectListTarget.classList.remove('hidden');
                }
            }
        })

        // @ts-ignore
        this.inputTarget.addEventListener('input', function(e){
            self.refresh_list()
        });

        // @ts-ignore
        const width = self.inputTarget.offsetWidth;
        // @ts-ignore
        self.selectListTarget.style.width = `${width}px`;
    }

    refresh_list(hidden = false){
        const self = this;
        // @ts-ignore
        const input = self.inputTarget;
        let url = input.dataset.url;
        const token = input.dataset.token;
        const value = input.value;
        if(value === ''){
            // @ts-ignore
            if(self.selectListTargets.length > 0){
                // @ts-ignore
                self.selectListTarget.classList.add('hidden');
            }
        } else {
            if(input.dataset.asJs == 'true'){
                // @ts-ignore
                Array.from(self.selectListTarget.children).forEach(child => {
                    // @ts-ignore
                    if(value != '' && !child.getElementsByTagName('span')[0].textContent.trim().split(' - ')[0].startsWith(value)){
                        // @ts-ignore
                        child.classList.add('hidden');
                    } else {
                        // @ts-ignore
                        child.classList.remove('hidden');
                    }
                })
            } else{
                const current_page = window.location.href
                url = url + `?query=${value}&token=${token}&hidden=${hidden}`
                Turbo.visit(url, {action: 'replace'});
                window.history.replaceState({additionalInformation: 'Replacing temp url'}, 'VATATIS', current_page);
            }
        }
    }

    select(event: PointerEvent){
        // @ts-ignore
        const target : HTMLElement = event.target;

        let name: string;
        let id: string;

        if(target.tagName == 'SPAN'){
            name = target.innerText;
            id = target.dataset.id;
        } else {
            const elem = target.getElementsByTagName('span')[0]
            name = elem.innerText;
            id = elem.dataset.id;
        }

        // @ts-ignore
        this.inputTarget.value = name;
        // @ts-ignore
        this.valueTarget.value = id;
        this.refresh_list(true)

        const evt = new Event('autocomplete_select', {
            bubbles: true,
            cancelable: true,
            composed: false
        });

        // @ts-ignore
        this.valueTarget.dispatchEvent(evt);


    }

    select_toggle(){
        // @ts-ignore
        this.selectListTarget.classList.toggle('hidden');
    }

}
