import { CookiesEu } from "src/common/ts/cookies_eu";
import "controllers"
import { Turbo } from "@hotwired/turbo-rails"
import '@materializecss/materialize/dist/js/materialize'
import * as Sentry from '@sentry/browser'

require("@rails/activestorage").start();
require("../channels");
// @ts-ignore
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

// @ts-ignore
const sounds = require.context("../sounds", true);
const soundPath = (name) => sounds(name, true);

document.addEventListener('turbo:load', function () {
    new CookiesEu();
})

Turbo.start();

const sentry_run = SENTRY_ON;
const sentry_dsn = SENTRY_DSN;
let environment = SENTRY_ENV.replace('/', '-');
if (environment === 'master') {
    environment = 'production'
}
if (environment !== 'production' && environment !== 'development') {
    environment = `review-${environment}`;
}
const release = SENTRY_COMMIT;
const trace_rate = environment === 'development' ? 0.1 : 0.4

if (sentry_run == '1') {
    Sentry.init({
        dsn: sentry_dsn,
        release: release,
        environment: environment,

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration()
        ],
        ignoreErrors: ['AbortError'],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampler: samplingContext => {
            if (samplingContext.parentSampled !== undefined) {
                return samplingContext.parentSampled
            }
            if (samplingContext.transactionContext.name === '/health') {
                // Drop this transaction, by setting its sample rate to 0%
                return 0;
            } else {
                // Default sample rate for all others (replaces tracesSampleRate)
                return trace_rate;
            }
        },
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /https:\/\/vatatis\.nz/, /https:\/\/www.vatatis\.nz/]
    });
}
