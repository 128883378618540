import {Controller} from "@hotwired/stimulus"
import loader from '@monaco-editor/loader';
import * as intellisense from './intellisense';

export default class extends Controller{
    static targets = ["editorContainer"]
    static values = {
        language: String,
        format: String,
        node: String,
        form: String,
        field: String,
        data: String
    }
    connect(){

        const self = this;
        if(this.hasEditorContainerTarget){
            loader.init().then(monaco => {
               fetch(`/atis_formats/${this.formatValue}/intellisense_variables`)
                    .then(x => x.json())
                    .then(data => {
                        intellisense.ConfigureIntellisense(monaco, data);
                        const editor = monaco.editor.create(this.editorContainerTarget, {
                            theme: 'vs-dark',
                            model: monaco.editor.createModel(this.dataValue, this.languageValue),
                            wordWrap: "on",
                            automaticLayout: true,
                            minimap: {
                                enabled: false
                            },
                            scrollbar: {
                                vertical: 'auto'
                            },
                            codeLens: false
                        })

                        const form = document.getElementById(this.formValue);
                        form.addEventListener("formdata", e =>
                        {
                            e.formData.append(this.fieldValue, editor.getModel().getValue());
                        });
                    })
            })
        }
    }

}
