import {Controller} from "@hotwired/stimulus"


export default class extends Controller {
    static targets = ["defaults", "defaultTemplate"]

    add_default(){
        // @ts-ignore
        const current_default_count = parseInt(this.defaultsTarget.dataset.defaults);

        // @ts-ignore
        const html = this.defaultTemplateTarget.content.cloneNode(true);
        html.querySelectorAll('[id="default_$$"]')[0].setAttribute('id', `default_${current_default_count}`)
        html.querySelectorAll('button')[0].setAttribute('data-default', `${current_default_count}`)
        const default_input_div = html.querySelectorAll('[id="default___"]')[0]
        default_input_div.setAttribute('id', `default_${current_default_count}`)
        default_input_div.setAttribute('name', `default_${current_default_count}`)
        const default_label_div = html.querySelectorAll('[for="default___"]')[0]
        default_label_div.setAttribute('for', `default_${current_default_count}`)

        const default_heading_input_div = html.querySelectorAll('[id="default_letter___"]')[0]
        default_heading_input_div.setAttribute('id', `default_letter_${current_default_count}`)
        default_heading_input_div.setAttribute('name', `default_letter_${current_default_count}`)
        const default_heading_label_div = html.querySelectorAll('[for="default_letter___"]')[0]
        default_heading_label_div.setAttribute('for', `default_letter_${current_default_count}`)

        const remove_button = html.querySelectorAll('[data-default="$$"]')[0]
        remove_button.setAttribute('data-default', `${current_default_count}`)

        // @ts-ignore
        this.defaultsTarget.append(html);
        // @ts-ignore
        this.defaultsTarget.dataset.defaults = current_default_count + 1;
    }

    remove_default(event){
        let elem = event.target;
        while(elem.tagName != 'BUTTON'){
            elem = elem.parentElement;
        }
        const defa = elem.dataset.default;
        document.getElementById(`default_${defa}`).remove();
    }
}
