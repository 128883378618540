import {Controller} from "@hotwired/stimulus"


export default class extends Controller{
    static targets = ['pageFill']

    connect(){
        const self = this;
        self.pageFill();
        window.addEventListener('resize', function(){
            self.pageFill();
        })

    }

    pageFill(){
        // @ts-ignore
        const divs = this.pageFillTargets;
        const nav = document.getElementsByTagName('nav')[0];
        const footer = document.getElementsByClassName('page-footer')[0];
        divs.map(div => div.style.minHeight = 0+'px');
        // @ts-ignore
        let height = window.outerHeight - nav.offsetHeight - footer.offsetHeight;
        if(height  < 0){
            height = 0;
        }
        height += divs[0].children[0].offsetHeight;
        divs.map(div => div.style.minHeight = height +'px');
    }




}