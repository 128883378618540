import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails";

export default class extends Controller{
    static targets = ['selectList', 'selectText', 'selectField', 'selectButton', 'initialSelected']
    active_elem: HTMLElement;

    connect(){
        const self = this;
        // @ts-ignore
        if(self.hasInitialSelectedTarget){
            // @ts-ignore
            this.active_elem = this.initialSelectedTarget;
        }
        window.addEventListener('click', function(e){
            // @ts-ignore
            if(!(self.selectButtonTarget == e.target || self.selectButtonTarget.contains(e.target))){
                // @ts-ignore
                self.selectListTarget.classList.add('hidden');
            }
        })

        // @ts-ignore
        const width = self.selectButtonTarget.offsetWidth;
        // @ts-ignore
        self.selectListTarget.style.width = `${width}px`;
    }

    select(event: PointerEvent){
        // @ts-ignore
        const target : HTMLElement = event.target;

        let name: string;
        let id: string;

        if(target.tagName == 'SPAN'){
            name = target.innerText;
            id = target.dataset.id;
        } else {
            const elem = target.getElementsByTagName('span')[0]
            name = elem.innerText;
            id = elem.dataset.id;
        }

        // @ts-ignore
        this.selectTextTarget.innerText = name;
        // @ts-ignore
        this.selectFieldTarget.value = id;
        // @ts-ignore
        this.selectFieldTarget.dispatchEvent(new Event('change'))

        if(this.active_elem !== undefined){
            this.active_elem.getElementsByTagName('span')[1].classList.add('hidden')
        }

        if(target.tagName === 'SPAN'){
            target.parentElement.getElementsByTagName('span')[1].classList.remove('hidden');
            this.active_elem = target.parentElement;
        }
        else {
            target.getElementsByTagName('span')[1].classList.remove('hidden');
            this.active_elem = target;
        }
    }

    select_toggle(){
        // @ts-ignore
        this.selectListTarget.classList.toggle('hidden');
    }

}
