import {Controller} from "@hotwired/stimulus"

export default class extends Controller{
    static targets = ["main", "modal", "background"]

    connect(){
        // @ts-ignore
        const modal = this.modalTarget;
        // @ts-ignore
        const background = this.backgroundTarget;

        setTimeout(function(){
            background.classList.replace('opacity-0', 'opacity-100');
            modal.classList.add('opacity-100', 'translate-y-0', 'sm:scale-100')
            modal.classList.remove('opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95');
        }, 200)

    }

    close(){
        const self = this;
        // @ts-ignore
        const modal = this.modalTarget;
        // @ts-ignore
        const background = this.backgroundTarget;

        background.classList.replace('opacity-100', 'opacity-0');
        modal.classList.add('opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95')
        modal.classList.remove('opacity-100', 'translate-y-0', 'sm:scale-100');
        setTimeout(function(){
            background.classList.add('hidden')
        }, 300)
        setTimeout(function(){
            // @ts-ignore
            self.mainTarget.remove();
        }, 300)
    }

}