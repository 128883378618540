const liquidTags = [
    'if',
    'else',
    'elseif',
    'endif',
    'render',
    'assign',
    'capture',
    'endcapture',
    'case',
    'endcase',
    'comment',
    'endcomment',
    'cycle',
    'decrement',
    'for',
    'endfor',
    'include',
    'increment',
    'layout',
    'raw',
    'endraw',
    'render',
    'tablerow',
    'endtablerow',
    'unless',
    'endunless'
];

const liquidFilters = [
    'to_phonetic',
    'abs',
    'append',
    'at_least',
    'at_most',
    'capitalize',
    'ceil',
    'compact',
    'date',
    'default',
    'divided_by',
    'downcase',
    'escape',
    'escape_once',
    'first',
    'floor',
    'join',
    'json',
    'last',
    'lstrip',
    'map',
    'minus',
    'modulo',
    'newline_to_br',
    'plus',
    'prepend',
    'remove',
    'remove_first',
    'replace',
    'replace_first',
    'reverse',
    'round',
    'rstrip',
    'size',
    'slice',
    'sort',
    'sort_natural',
    'split',
    'strip',
    'strip_html',
    'strip_newlines',
    'times',
    'truncate',
    'truncatewords',
    'uniq',
    'upcase',
    'url_decode',
    'url_encode',
    'where'
]

// Helper function to return the monaco completion item type of a thing
function getType(thing, isMember) {
    isMember =  (isMember === undefined) ? (typeof isMember == "boolean") ? isMember : false : false; // Give isMember a default value of false

    switch ((typeof thing).toLowerCase()) {
        case "object":
            return monaco.languages.CompletionItemKind.Class;

        case "function":
            return (isMember) ? monaco.languages.CompletionItemKind.Method : monaco.languages.CompletionItemKind.Function;

        default:
            return (isMember) ? monaco.languages.CompletionItemKind.Property : monaco.languages.CompletionItemKind.Variable;
    }
}

function getLiquidContextInfo(model, position, triggerCharacter) {
    let inTag;
    let inObject;
    let showTags;
    let showFilters;

    const findStart = model.findPreviousMatch('\\{(%|\\{)', position, true, false, null, true);
    if (findStart && findStart.matches && !position.isBefore(findStart.range.getEndPosition())) {
        if (findStart.matches[1] === '%') {
            inTag = true;
        } else if (findStart.matches[1] === '{') {
            inObject = true
        }

        const searchPattern = inTag ? '%}' : '}}';
        const findEnd = model.findNextMatch(searchPattern, position, false, false, null, true);
        const currentRange = findStart.range.plusRange(findEnd.range);
        if (currentRange.containsPosition(position)) {
            if (inTag) {
                const findTagName = model.findNextMatch('\\{%\\s*([a-zA-Z-_]+)', findStart.range.getStartPosition(), true, false, null, true);
                if (findTagName && currentRange.containsRange(findTagName.range) && findTagName.matches.length > 1) {
                    if (findTagName.matches[1] === 'assign') {
                        showFilters = true;
                    } else {
                        showTags = false;
                    }
                } else {
                    showTags = true;
                }
            } else {
                showFilters = true;
            }
        }
    }

    return {
        showFilters,
        showTags,
        inTag,
        inObject
    };
}

function get_var_suggestions(model, position, variables) {
    const last_chars = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 0,
        endLineNumber: position.lineNumber,
        endColumn: position.column
    });
    const words = last_chars.replace("\t", "").split(" ");
    const active_typing = words[words.length - 1]; // What the user is currently typing (everything after the last space)
    // If the last character typed is a period then we need to look at member objects of the obj object
    const is_member = active_typing.charAt(active_typing.length - 1) === ".";
    // Array of autocompletion results
    const result = [];
    // Used for generic handling between member and non-member objects
    let last_token = variables;
    let prefix = '';
    if (is_member) {
        // Is a member, get a list of all members, and the prefix
        const parents = active_typing.substring(0, active_typing.length - 1).split(".");
        last_token = variables[parents[0]];
        prefix = parents[0];

        // Loop through all the parents the current one will have (to generate prefix)
        for (let i = 1; i < parents.length; i++) {
            let propToLookFor = parents[i];

            // Support for arrays
            const isPropAnArray = propToLookFor.charAt(propToLookFor.length - 1) === ']';
            if (isPropAnArray)
                propToLookFor = propToLookFor.split('[')[0];

            if (last_token.hasOwnProperty(propToLookFor)) {
                prefix += '.' + propToLookFor;
                last_token = last_token[propToLookFor];

                if (isPropAnArray && Array.isArray(last_token))
                {
                    last_token = last_token[0];
                }
            } else {
                // Not valid
                return result;
            }
        }

        prefix += '.';
    }

    // Array properties
    if (Array.isArray(last_token))
        last_token = { length: 0 };

    // Get all the child properties of the last token
    for (let prop in last_token) {
        // Do not show properites that begin with "__"
        if (last_token.hasOwnProperty(prop) && !prop.startsWith("__")) {
            // Get the detail type (try-catch) incase object does not have prototype
            let details = '';

            try {
                details = last_token[prop].__proto__.constructor.name;
            } catch (e) {
                details = typeof last_token[prop];
            }

            if(details === 'String'){
                details = last_token[prop];
            }

            // Create completion object
            const to_push = {
                label: prefix + prop,
                kind: getType(last_token[prop], is_member),
                detail: details,
                insertText: prop
            };

            // Change insertText and documentation for functions
            if (to_push.detail.toLowerCase() === 'function') {
                to_push.insertText += "(";
                to_push.documentation = (last_token[prop].toString()).split("{")[0]; // Show function prototype in the documentation popup
            }

            // Add to final results
            result.push(to_push);
        }
    }

    return {
        suggestions: result
    };
}

export function ConfigureIntellisense(monaco, variables) {
    monaco.languages.registerCompletionItemProvider('liquid', {
        triggerCharacters: [' ', '{', '.'],
        provideCompletionItems: (model, position, context, token) => {
            let suggestions = [];
            let items = [];

            if (context.triggerCharacter === ' ') {
                const startTrigger = model.getValueInRange(new monaco.Range(position.lineNumber, position.column - 3, position.lineNumber, position.column - 1));
                if (!(startTrigger !== '{%' && !startTrigger.endsWith('|'))) {

                    const liquidContext = getLiquidContextInfo(model, position, context.triggerCharacter);
                    if (liquidContext.showFilters) {
                        items = liquidFilters;
                    } else if (liquidContext.showTags) {
                        items = liquidTags.filter((value) => { return !value.startsWith('end') });
                    }


                    suggestions.push(...items.map((value) => {
                        return {
                            label: value,
                            kind: monaco.languages.CompletionItemKind.Keyword,
                            insertText: value,
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.KeepWhitespace
                        }
                    }));

                }
            }

            const lastThreeChars = model.getValueInRange(new monaco.Range(position.lineNumber, position.column - 3, position.lineNumber, position.column));
            const command = model.getValueInRange(new monaco.Range(position.lineNumber, position.column - 3, position.lineNumber, position.column - 1));
            const lastChar = model.getValueInRange(new monaco.Range(position.lineNumber, position.column - 1, position.lineNumber, position.column));
            const isIdentifyingVariable = lastThreeChars === '{{ ' || lastChar === '.' || (lastChar === ' ' && liquidTags.includes(command));
            if (isIdentifyingVariable){
                const var_suggestions = get_var_suggestions(model, position, variables)
                if (var_suggestions.suggestions !== undefined){
                    suggestions.push(...var_suggestions.suggestions);
                }
            }
            return { suggestions };
        }
    });
}
