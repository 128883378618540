import {Controller} from "@hotwired/stimulus"
// @ts-ignore
import notification from '../sounds/notification.mp3';

export default class extends Controller{
    last_play_time: number = Date.now()-((1000*60)*10);
    audio = new Audio(notification);

    connect(){
        const self = this;
        self.audio.volume = 0.5;
        document.documentElement.addEventListener('turbo:before-stream-render', function (e){
            // @ts-ignore
            if(e.target.action == 'replace' && e.target.target.match(/\w{3,4}\_stream/)){
                const diff = (1000*60);
                const minute_ago = Date.now() - diff;
                if(self.last_play_time < minute_ago){
                    self.last_play_time = Date.now();
                    // @ts-ignore
                    self.audio.play();
                }

            }
        })
    }
}