import {Controller} from "@hotwired/stimulus"


export default class extends Controller{
    static targets = ['li']

    remove(){
        // @ts-ignore
        this.liTarget.remove();
    }

}