// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { registerFlightLevelTechComponentControllers } from "@FlightLevelTech/component-library"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.[jt]s$/)
registerFlightLevelTechComponentControllers(application)
application.load(definitionsFromContext(context))
