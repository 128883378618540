import Cookies from 'js-cookie';
export class CookiesEu {
  constructor() {
    const cookiesEuOKButton = document.querySelector('.js-cookies-eu-ok');

    if (cookiesEuOKButton) {
      this.addListener(cookiesEuOKButton);
    }
  }

  addListener(target) {
    // Support for IE < 9
    if (target.attachEvent) {
      target.attachEvent('onclick', this.setCookie);
    } else {
      target.addEventListener('click', this.setCookie, false);
    }
  }

  setCookie() {
    const isSecure = location.protocol === 'https:';
    Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: isSecure });

    const container = document.querySelector('.js-cookies-eu');
    container.parentNode.removeChild(container);
  }
}

