import {Controller} from "@hotwired/stimulus"


export default class extends Controller {
    static targets = ['draggable', 'remove', 'removeBg', 'removeBorder']
    remove_over = false;
    handlers = [];

    draggableTargetConnected(e){
        const self = this;
        e.addEventListener('dragstart', function(e){
            self.drag_start(e, self);
        })
        e.addEventListener('dragend', function(e){
            self.drag_stop(e, self);
        })
    }

    connect() {
        const self = this;
        // @ts-ignore
        this.draggableTargets.forEach(t => t.addEventListener('dragstart', function(e){
            self.drag_start(e, self);
        }))
        // @ts-ignore
        this.draggableTargets.forEach(t => t.addEventListener('dragend', function(e){
            self.drag_stop(e, self);
        }))
        // @ts-ignore
        this.removeTarget.addEventListener('dragenter', function(e){
            self.remove_drag_over(e, self);
        })
        // @ts-ignore
        this.removeTarget.addEventListener('dragover',function(e){
            self.remove_drag_over(e, self);
        })
        // @ts-ignore
        this.removeTarget.addEventListener('dragleave', function(e){
            self.remove_drag_leave(e, self);
        })
        // @ts-ignore
        this.removeTarget.addEventListener('drop', function(e){
            self.remove_drag_drop(e, self);
        })
    }

    drag_start(e, self){
        e.dataTransfer.setData('application/position', e.target.id);
        document.getElementById('dragged_airport').dataset.airport = e.target.id;
        self.draggableTargets.forEach((target) => {
            target.classList.remove('hover:bg-gray-200')
        });
        e.target.classList.add('bg-gray-200');
        const parent_id = e.target.parentElement.id;
        let position = 0
        const pos_type = parent_id.split('_')[0]
        e.dataTransfer.setData('application/priority', parent_id.split('_')[1]);
        while(true){
            let elem = document.getElementById(`${pos_type}_${position}`)
            if(elem === null){
                break;
            }
            elem.addEventListener('dragenter', self.pos_drag_over)
            elem.addEventListener('dragover',self.pos_drag_over)
            elem.addEventListener('drop',self.drag_drop)

            position = position + 1
        }
    }

    drag_stop(e, self){
        document.getElementById('dragged_airport').dataset.airport = null;
        self.draggableTargets.forEach((target) => {
            target.classList.add('hover:bg-gray-200')
        });
        e.target.classList.remove('bg-gray-200');
        let position = 0
        const pos_type = e.target.dataset.type
        while(true){
            let elem = document.getElementById(`${pos_type}_${position}`)
            if(elem === null){
                break;
            }
            elem.removeEventListener('dragenter', self.pos_drag_over)
            elem.removeEventListener('dragover',self.pos_drag_over)
            elem.removeEventListener('drop',self.drag_drop)

            position = position + 1
        }
    }

    drag_drop(e){
        // @ts-ignore
        const new_priority = parseInt(this.id.split('_')[1]);
        const old_priority = parseInt(e.dataTransfer.getData('application/priority'));
        const id = e.dataTransfer.getData('application/position');

        if(old_priority == new_priority){
            return;
        }

        const elem = this;
        // @ts-ignore
        const elems = elem.parentNode.children;
        const data = {
            positions: []
        }
        Array.from(elems).forEach(position => {
            // @ts-ignore
            if(position.id.endsWith('header')){
                return;
            }
            // @ts-ignore
            const pos = position.id.split('_')[1]
            // @ts-ignore
            const pos_id = position.children[0].id;
            data['positions'].push({
                position: pos,
                id: pos_id
            })
        })

        //@ts-ignore
        const token = document.getElementsByName('csrf-token')[0].content;
        fetch(`/airport_positions/${id}/reorder_nodes`, {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': token,
                'Content-Type': 'application/json',
                'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
            },
            body: JSON.stringify(data),
            credentials: 'same-origin'
        }).then(r => {})
    }

    pos_drag_over(e){
        e.preventDefault();

        const id = document.getElementById('dragged_airport').dataset.airport;
        if(id === null || id === undefined){
            return;
        }

        const origin_child = document.getElementById(id)
        const target_parent = e.currentTarget;
        if(target_parent.children[0] == origin_child){
            return;
        }
        const origin_parent = document.getElementById(id).parentElement;
        const target_child = target_parent.children[0];

        origin_parent.appendChild(target_child);
        target_parent.appendChild(origin_child);
    }

    remove_drag_over(e, self){
        if(e.dataTransfer.types[0] !== 'application/position' && e.dataTransfer.types[0] !== 'application/priority'){
            return false;
        }
        e.preventDefault();
        self.remove_over = true;
        // @ts-ignore
        const bg = self.removeBgTarget;

        if(bg !== undefined){
            bg.classList.remove('bg-white')
            bg.classList.add('bg-gray-200')
        }

        // @ts-ignore
        const border = self.removeBorderTarget;
        if(bg !== undefined){
            border.classList.remove('border-red-300')
            border.classList.add('border-red-400')
        }
    }

    async remove_drag_leave(e, self) {
        // @ts-ignore
        if(e.dataTransfer.types[0] !== 'application/position' && e.dataTransfer.types[0] !== 'application/priority'){
            return false;
        }
        self.remove_over = false;

        setTimeout(function (){
            if(self.remove_over){
                return;
            }
            const bg = self.removeBgTarget;
            if (bg !== undefined) {
                bg.classList.add('bg-white')
                bg.classList.remove('bg-gray-200')
            }

            // @ts-ignore
            const border = self.removeBorderTarget;
            if (bg !== undefined) {
                border.classList.add('border-red-300')
                border.classList.remove('border-red-400')
            }
        }, 100)

    }

    remove_drag_drop(e, self){
        const bg = self.removeBgTarget;
        if (bg !== undefined) {
            bg.classList.add('bg-white')
            bg.classList.remove('bg-gray-200')
        }

        // @ts-ignore
        const border = self.removeBorderTarget;
        if (bg !== undefined) {
            border.classList.add('border-red-300')
            border.classList.remove('border-red-400')
        }

        const id = e.dataTransfer.getData('application/position');
        const elem = document.getElementById(id)
        let parent_elem = elem.parentElement
        const parent_id = parent_elem.id;
        let position = parseInt(parent_id.split('_')[1])
        const pos_type = parent_id.split('_')[0]
        elem.remove();
        while(true){
            const next_parent = document.getElementById(`${pos_type}_${position+1}`)
            if(next_parent === null){
                document.getElementById(`${pos_type}_${position}`).remove();
                break;
            }
            const child = next_parent.children[0];
            parent_elem.appendChild(child);

            parent_elem = next_parent
            position = position + 1
        }
        // @ts-ignore
        const token = document.getElementsByName('csrf-token')[0].content;
        fetch(`/airport_positions/${id}/remove`, {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': token,
                'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
            },
            credentials: 'same-origin'
        }).then(r => {})
    }
}