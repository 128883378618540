import {Controller} from "@hotwired/stimulus"


export default class extends Controller {
    static targets = ['input']

    get_metar() {
        // @ts-ignore
        const icao = this.inputTarget.value
        fetch(`/metars/passthrough?icao=${icao}`)
            .then(r => r.text())
            .then(data => {
                // @ts-ignore
                document.getElementById('parameters_metar_value').value = data.split(/\r?\n/)[0]
            })

    }

}
