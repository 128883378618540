import {Controller} from "@hotwired/stimulus"


export default class extends Controller {
    static targets = ['main', 'name', 'magVar', 'button', 'form', 'fieldElevation']


    connect(){
        const self = this;
        // @ts-ignore
        this.mainTarget.addEventListener('autocomplete_select', function(e){
            const id = e.target.value;
            fetch(`/configured_airports/airport_data?id=${id}`, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            })
            .then(response => {
                if(response.ok){
                    response.json().then(json =>{
                        // @ts-ignore
                        self.nameTarget.value = json.name;
                        // @ts-ignore
                        self.magVarTarget.value = json.mag_var;
                        // @ts-ignore
                        self.fieldElevationTarget.value = json.field_elevation;
                    });
                } else {
                    // @ts-ignore
                    self.nameTarget.value = 'Error, try selecting the ICAO again';
                }
            });
        })

        // @ts-ignore
        this.formTarget.addEventListener('submit', function(){
            self.submit();
            return true;
        })
    }

    submit(){
        // @ts-ignore
        this.buttonTargets.forEach(function(button){
            button.disabled = true;
            button.classList.add('shadow-none', 'hover:shadow-none', 'bg-gray-500', 'hover:bg-gray-500', 'cursor-default')
        })
        return true;
    }
}