import {Controller} from "@hotwired/stimulus"

export default class extends Controller{

    search(){
        const elem = document.getElementById("result_placeholder");
        // @ts-ignore
        if(elem !== undefined && elem !== null){
            // @ts-ignore
            elem.remove();
        }
    }

    remove(e){
        let airports = document.getElementById('airports');
        // @ts-ignore
        let parent = e.target;
        let id = ''
        while(true){
           parent = parent.parentElement;
           id = parent.id;
           if(id.match(/\w{3,4}_result/)){
               break;
           }
        }
        let airport = id.match(/(\w{3,4})_result/)[1]
        let airports_value = airports.getAttribute('value');
        const airports_values_temp = airports_value.split(',');
        let airports_values = []
        while(airports_values_temp.length) airports_values.push(airports_values_temp.splice(0,2));
        airports_values = airports_values.filter(a => a[0] !== airport);
        airports_value = airports_values.join(',');
        airports.setAttribute('value', airports_value);
        parent.remove();
    }
}